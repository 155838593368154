<template>
  <div>
    <div>
      <div class="row">
        <div
          class="card card-custom bgi-no-repeat gutter-b"
          style="height: 1400px; width: 100%; background-position-x: center; background-position-y: top; background-size: 100% auto; background-image: url(media/bg/permohonan_data_2.png)"
        >
          <!--begin::Body-->
          <div class="card-body">
            <div class="card-body d-flex flex-column">
              <!--begin::Items-->
              <div class="flex-grow-1" style="height: 1250px;">
                &nbsp;
              </div>
              <!--end::Items-->
              <!--begin::Action-->
              <div class="pt-10 pb-5 text-center">
                <router-link
                  class="btn btn-icon w-auto btn-clean btn-lg d-inline-flex align-items-center btn-lg px-2 mr-5"
                  :to="{ name: 'complaint-data-application' }"
                >
                  <span
                    class="text-dark-50 font-weight-bolder font-size-base mr-3"
                    >Buat Permintaan Data Sekarang</span
                  >
                  <span class="symbol symbol-35 symbol-primary">
                    <i class="socicon-telegram icon-3x"></i>
                  </span>
                </router-link>
              </div>
              <!--end::Action-->
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "complaint-receipt-data",
  components: {},
  data() {
    return {
      latestUpdate: Date.now(),
      bodyBgVariant: "dark",
      footerTextVariant: "dark"
    };
  },
  beforeMount() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.COMPLAINT_DATA"), route: "complaint-receipt-data" },
      { title: this.$t("MENU.COMPLAINT_RECEIPT_DATA") }
    ]);
  },
  methods: {
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    registerClicked() {
      this.$router.push({ name: "register" });
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  }
};
</script>
